<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouvelle fonction" :active.sync="popupFonction">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="Libelle_Fonct" v-model="Libelle_Fonct" />
              <span class="text-danger text-sm"  v-show="errors.has('Libelle_Fonct')">{{ errors.first('Libelle_Fonct') }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="creatFonction" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="Libelle_Fonct = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier une fonction" :active.sync="popupFonctionUpdate">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input class="w-full" label="Libelle" type="text" v-validate="'required'" name="Libelle_Fonct" v-model="Libelle_Fonct" />
              <span class="text-danger text-sm"  v-show="errors.has('Libelle_Fonct')">{{ errors.first('Libelle_Fonct') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="updateFonction" :disabled="!validateForm">Modifier</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="Libelle_Fonct = '';  popupFonctionUpdate = false;">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table multiple stripe v-model="selected" pagination max-items="10" search :data="fonctionData" noDataText="Aucune donnée">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupFonction=true">Nouvelle fonction</vs-button>
              </template>
              <template slot="thead">
                <vs-th>
                  N°
                </vs-th>
                <vs-th sort-key="Libelle_Fonct">
                  Libelle
                </vs-th>
                <vs-th>
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].Libelle_Fonct">
                    {{data[indextr].Libelle_Fonct}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateFonctionShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupFonction: false,
      popupFonctionUpdate: false,
      // status: false,
      Libelle_Fonct: '',
      selected:[],
      fonctions:[],
      fonctionId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.Libelle_Fonct !== ''
    },
    fonctionData () {
      return this.$store.state.fonction.fonctions
    }
  },

  methods: {
    creatFonction () {
      const payload = {
        Libelle_Fonct: this.Libelle_Fonct
        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonction/addFonction', payload)
        .then(() => {
          this.resete_data()
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupFonction = false
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    confirmDeleteRecord (data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `voulez-vous vraiment supprimer "${data.Libelle_Fonct}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord(data)
      })
    },
    deleteRecord (data) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonction/removeFonction', data._id)
        .then(() => {
          //console.log('removedFonction', response.data)
          this.$vs.loading.close()
          this.showDeleteSuccess(data)
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess (data) {
      this.$vs.notify({
        color: 'primary',
        title: 'success',
        text: `${data.Libelle_Fonct} supprimé`
      })
    },
    getfonctions () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonction/getfonctions')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    updateFonctionShowForm (data) {

      this.Libelle_Fonct = data.Libelle_Fonct
      // this.status = data.status
      this.fonctionId = data._id
      this.popupFonctionUpdate = true
    },
    updateFonction () {
      const payload = {
        Libelle_Fonct: this.Libelle_Fonct,
        id: this.fonctionId
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonction/updateFonction', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.getfonctions()
          this.$vs.loading.close()
          this.popupFonctionUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.popupFonctionUpdate = false
        })
    },
    resete_data () {
      this.Libelle_Fonct = ''
    }
  },
  created () {
    this.getfonctions()
  }
}
</script>

